export const Links = {
    facebook: "https://www.facebook.com/people/Miaru/100093004904252/",
    twitter: "https://twitter.com/Miaru_co",
    instagram: "https://www.instagram.com/miaru_co/",
    youtube: "https://www.youtube.com/@miaru_co",
    tiktok: "https://www.tiktok.com/@miaru735", 
    linkedin: "https://www.linkedin.com/company/miaru/",
    medium: "https://miaru.medium.com/",
    store: "https://shop.miaru.co/",
    email: "mailto:contact@miaru.co",
    android: "https://play.google.com/", // need to create still
    ios: "https://apps.apple.com/", // need to create still
    pressKit: "https://drive.google.com/drive/folders/1b_I3eyKYH_yWK-ulN1nILTjsAVOxXKQe?usp=sharing",
    ticket: "https://miaru.freshdesk.com/support/tickets/new",


    // app links 
    home: "/"

};