import React from "react";
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import {ErrorBoundary} from "src/common/ErrorBoundary";
import { translate } from "src/common/I18N";

const AppPage = () => {
    
    return (
        <ErrorBoundary type={"Miaru-Error-Page"}>
          <div className="miaru-error-page">
            <h1>{translate(ds.errorPageHeading.stringId, ds.errorPageHeading.defaultString)}</h1>
            <h2>{translate(ds.errorPageSubHeading.stringId, ds.errorPageSubHeading.defaultString)}</h2>
            <img id="error-mascot" alt={translate(ds.errorPageImgAltText.stringId, ds.errorPageImgAltText.defaultString)} title={translate(ds.errorPageImgAltText.stringId, ds.errorPageImgAltText.defaultString)} src="assets/img/mascot/pose1.png"/>
          </div>
        </ErrorBoundary>
    );
};

export default AppPage;
