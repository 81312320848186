export const DefaultStrings = {
    // language switcher
    languageSwither: {stringId: "languageswitcher.selectorText", defaultString: "Site Language:"},
    english: {stringId: "languageswitcher.languages.us", defaultString: "English"},
    german: {stringId: "languageswitcher.languages.de", defaultString: "German"},
    chinese: {stringId: "languageswitcher.languages.cn", defaultString: "Chinese"},
    japanese: {stringId: "languageswitcher.languages.jp", defaultString: "Japanese"},
    korean: {stringId: "languageswitcher.languages.kr", defaultString: "Korean"},
    italian: {stringId: "languageswitcher.languages.it", defaultString: "Italian"},
    french: {stringId: "languageswitcher.languages.fr", defaultString: "French"},
    spanish: {stringId: "languageswitcher.languages.es", defaultString: "Spanish"},
    portugeas: {stringId: "languageswitcher.languages.pt", defaultString: "Portuguese"},
    russia: {stringId: "languageswitcher.languages.ru", defaultString: "Russian"},
    bosnian: {stringId: "languageswitcher.languages.ba", defaultString: "Bosnian"},
    serbian: {stringId: "languageswitcher.languages.rs", defaultString: "Serbian"},
    croatian: {stringId: "languageswitcher.languages.hr", defaultString: "Croatian"},

    // ErrorPage
    errorPageHeading: {stringId: "errorPage.heading", defaultString: "Sorry"},
    errorPageSubHeading: {stringId: "errorPage.subheading", defaultString: "Something went wrong"},
    errorPageImgAltText: {stringId: "errorPage.imgAltText", defaultString: "Miaru Moscot Luffy looking sad with frown face and tear in right eye."},

    // Header
    headerTitle: {stringId: "header.title", defaultString: "Miaru"},
};