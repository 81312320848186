import React from "react";
import {ErrorBoundary} from "src/common/ErrorBoundary";


const AppPage = () => {

    return (
        <ErrorBoundary type={"Miaru-Landing"}>
          <div className="miaru-landing">
              {/** Intro Section Start */}
              <div className="container">
                <div className="row align-items-center py-5">
                  <div className="col-lg-7 text-center text-lg-start">
                    <h1 className="fw-bold lh-1 mb-3">
                      {"Miaru App"}
                    </h1>
                  </div>
                </div>
              </div>
              {/** Intro Section End */}

            


            

          </div>
        </ErrorBoundary>
    );
};

export default AppPage;
